import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import '../css/Navbar.css';
import { ThemeContext } from '../context/ThemeContext';
import { isAuthenticated, isAdmin } from '../auth'; // Import isAdmin

const Navbar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { theme, setTheme } = useContext(ThemeContext);
    const [userName, setUserName] = useState('');
    const [isAdminUser, setIsAdminUser] = useState(false); // State to track if user is admin

    useEffect(() => {
        if (isAuthenticated()) {
            const storedUser = JSON.parse(localStorage.getItem('user'));
            const storedUserName = storedUser?.firstName || '';
            setUserName(storedUserName);
            setIsAdminUser(isAdmin()); // Check if user is admin
        }
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('user');
        navigate('/');
    };

    const handleLogin = () => {
        navigate('/login');
    };

    const getNavLinkClass = (path) => {
        return location.pathname === path ? 'navbar-link active' : 'navbar-link';
    };

    const handleThemeChange = (e) => {
        setTheme(e.target.value);
        localStorage.setItem('theme-choice', e.target.value);
    };

    return (
        <nav className="navbar">
            <div className="navbar-container">
                <div className="navbar-left">
                    <Link to="/" className="navbar-logo">
                        <img src="/bg-guy.png" alt="Logo" className="navbar-logo-image" />
                    </Link>
                    <span className="navbar-title">Tammoo</span>
                </div>

                <div className="navbar-center">
                    {isAuthenticated() && (
                        <span className="navbar-user">Hi {userName}!</span>
                    )}
                    {!isAuthenticated() && (
                        <span className="navbar-tagline">AI-Powered Speech-to-Insight for Teachers</span>
                    )}
                </div>

                <div className="navbar-right">
                    <div className="theme-title">Theme Colors</div>
                    <select value={theme} onChange={handleThemeChange} className="theme-selector">
                        <option value="royal-elegance">Royal Elegance</option>
                        <option value="ocean-breeze">Ocean Breeze</option>
                        <option value="sunset-glow">Sunset Glow</option>
                        <option value="forest-calm">Forest Calm</option>
                        <option value="citrus-fresh">Citrus Fresh</option>
                        <option value="cool-mint">Cool Mint</option>
                        <option value="earthy-tones">Earthy Tones</option>
                    </select>
                    <ul className="navbar-menu">
                        {isAuthenticated() && (
                            <>
                                <li className="navbar-item">
                                    <Link to="/dash" className={getNavLinkClass('/dash')}>Dashboard</Link>
                                </li>
                                <li className="navbar-item">
                                    <Link to="/search" className={getNavLinkClass('/search')}>Search</Link>
                                </li>
                                <li className="navbar-item">
                                    <Link to="/reports" className={getNavLinkClass('/reports')}>Reports</Link>
                                </li>
                                <li className="navbar-item">
                                    <Link to="/classes" className={getNavLinkClass('/classes')}>Classes</Link>
                                </li>
                                <li className="navbar-item">
                                    <Link to="/terms" className={getNavLinkClass('/terms')}>Terms</Link>
                                </li>
                                {/* Admin-only Link */}
                                {isAdminUser && (
                                    <li className="navbar-item">
                                        <Link to="/newsletter" className={getNavLinkClass('/newsletter')}>Newsletter Editor</Link>
                                    </li>
                                )}
                                <li className="navbar-item">
                                    <button onClick={handleLogout} className="navbar-button navbar-link">
                                        Logout
                                    </button>
                                </li>
                            </>
                        )}
                        {!isAuthenticated() && (
                            <>
                                <li className="navbar-item">
                                    <Link to="/" className={getNavLinkClass('/')}>Home</Link>
                                </li>
                                <li className="navbar-item">
                                    <button onClick={handleLogin} className="navbar-button navbar-link">
                                        Login
                                    </button>
                                </li>
                            </>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
