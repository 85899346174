import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { setToken } from '../../auth';
import '../../css/Login.css';
import mascotImage from '../../assets/bg-guy.png';

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false); // Add loading state
    const navigate = useNavigate();

    // Clear local settings if user data changes
    const clearLocalSettings = () => {
        localStorage.removeItem('selectedClass');
        localStorage.removeItem('currentDate');
        localStorage.removeItem('dateRange');
        localStorage.removeItem('selectedName');
        localStorage.removeItem('selectedTerms');
    };

    const handleLogin = async (e) => {
        e.preventDefault();

        // Basic validation to ensure fields are not empty
        if (!email || !password) {
            alert('Please fill in both email and password fields.');
            return;
        }

        setLoading(true); // Set loading state to true before making request

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/auth/login`,
                { email, password }
            );

            // Extract and log necessary data
            const { token, refreshToken, user } = response.data;
            console.log('User authenticated:', user.email);

            // Check if current user data in local storage is different from the logged-in user
            const currentUser = JSON.parse(localStorage.getItem('user'));
            if (!currentUser || currentUser.email !== user.email) {
                clearLocalSettings(); // Clear local storage if user is different
            }

            // Push successful login event to dataLayer
            if (window.dataLayer) {
                window.dataLayer.push({
                    event: 'login',
                    userId: user.id || email // Use userId if available, else use email
                });
            }

            // Store user token and details
            setToken(token, refreshToken, user);
            localStorage.setItem('user', JSON.stringify(user));
            navigate('/dash'); // Redirect to dashboard
        } catch (error) {
            // Handle errors and provide user feedback
            const errorMessage = error.response?.data?.message || 'Login failed. Please check your credentials and try again.';

            // Push failed login event to dataLayer
            if (window.dataLayer) {
                window.dataLayer.push({
                    event: 'loginFailed',
                    errorMessage: errorMessage
                });
            }

            console.error('Login error:', errorMessage);
            alert(errorMessage);
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    return (
        <div className="login-page">
            <div className="login-container">
                <img src={mascotImage} alt="Mascot" className="mascot-login" />
                <h2 className="login-title">Tammoo Manager: Login</h2>
                <form onSubmit={handleLogin} className="login-form">
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        className="login-input"
                        disabled={loading} // Disable input during loading
                    />
                    <input
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        className="login-input"
                        disabled={loading} // Disable input during loading
                    />
                    <button type="submit" className="login-button" disabled={loading}>
                        {loading ? 'Logging in...' : 'Login'} {/* Display loading state */}
                    </button>
                </form>
                <Link to="/forgot-password" className="login-link">Forgot Password?</Link>
            </div>
        </div>
    );
};

export default Login;
