import React, { useState, useEffect } from "react";
import axios from "axios";
import "../../css/SyllabusView.css";

const SyllabusViewer = () => {
    const [headings, setHeadings] = useState([]);
    const [filteredHeadings, setFilteredHeadings] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState("All");
    const [selectedHeading, setSelectedHeading] = useState(null);
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [expanded, setExpanded] = useState({});
    const [activeIndex, setActiveIndex] = useState(null);
    const [expandedSections, setExpandedSections] = useState({}); // Track expanded state for all sections

    const toggleSection = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index)); // Toggle the clicked section
    };


    // Categories for filtering
    const categories = [
        { label: "All", filter: () => true },
        { label: "Creative", filter: (heading) => heading.includes("Creative") },
        { label: "English", filter: (heading) => heading.includes("English") },
        { label: "HSIE", filter: (heading) => {
                const keywords = ["History", "Geography", "Economics", "Civics"];
                return keywords.some(keyword => heading.includes(keyword));
            }},
        { label: "PDHPE", filter: (heading) => {
                const keywords = ["Movement", "Health", "Physical Education", "Well-being"];
                return keywords.some(keyword => heading.includes(keyword));
            }},
        { label: "Math", filter: (heading) => heading.includes("Math") },
        { label: "Sciences", filter: (heading) => heading.includes("Science") },
        { label: "Language", filter: (heading) => heading.includes("Language") },
    ];

    // Fetch top-level headings on component mount
    useEffect(() => {
        const fetchHeadings = async () => {
            try {
                const response = await axios.get("http://192.168.200.184:4000/syllabus/find/toplevel");
                setHeadings(response.data);
                setFilteredHeadings(response.data); // Initially show all headings
                console.log('HEADIN', response.data);
            } catch (err) {
                console.error("Error fetching top-level headings:", err);
                setError("Failed to load headings.");
            }
        };

        fetchHeadings();
    }, []);

    // Handle category filter
    const filterHeadings = (category) => {
        setSelectedCategory(category);
        const categoryFilter = categories.find((c) => c.label === category)?.filter;
        setFilteredHeadings(headings.filter((item) => categoryFilter(item)));
    };

    // Fetch hierarchical data for a selected heading
    const fetchData = async (fileName) => {
        setLoading(true);
        setError(null);

        try {
            const response = await axios.get(`http://192.168.200.184:4000/syllabus/search/${fileName}`);
            setSelectedHeading(fileName); // Update selected heading to fileName
            setData(response.data); // Store the entire hierarchy
            console.log("Fetched data:", response.data); // Debug log
        } catch (err) {
            console.error("Error fetching data:", err);
            setError("Failed to load data.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const fetchHeadings = async () => {
            try {
                const response = await axios.get("http://192.168.200.184:4000/syllabus/find/toplevel");
                console.log("Fetched headings:", response.data); // Debug log
                setHeadings(response.data);
                setFilteredHeadings(response.data); // Initially show all headings
            } catch (err) {
                console.error("Error fetching top-level headings:", err);
                setError("Failed to load headings.");
            }
        };

        fetchHeadings();
    }, []);



    // Format content with formatting details
    const formatContent = (content) => {
        let text = content.text;

        if (content.formatting?.bold) text = <b>{text}</b>;
        if (content.formatting?.italic) text = <i>{text}</i>;
        if (content.formatting?.underline) text = <u>{text}</u>;

        return text;
    };

    // Render the hierarchical structure
    const renderTables = (tables) => {
        return tables.map((table, tableIndex) => (
            <table key={tableIndex} className="syllabus-table">
                <tbody>
                {table.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                            <td key={cellIndex}>{cell}</td>
                        ))}
                    </tr>
                ))}
                </tbody>
            </table>
        ));
    };

    const renderAccordion = (subsections, parentPath = "") => {


        const toggleSection = (path) => {
            setExpandedSections((prevState) => ({
                ...prevState,
                [path]: !prevState[path], // Toggle the clicked section
            }));
        };

        return (
            <div className="accordion-container">
                {subsections.map((section, index) => {
                    const path = `${parentPath}-${index}`; // Unique key for each section

                    return (
                        <div key={path} className={`accordion-item ${expandedSections[path] ? "expanded" : ""}`}>
                            {/* Accordion Header */}
                            <div
                                className="accordion-header"
                                onClick={() => toggleSection(path)}
                            >
                                <h4>{section.heading}</h4>
                                <span>{expandedSections[path] ? "▲" : "▼"}</span> {/* Expand/Collapse Icon */}
                            </div>

                            {/* Accordion Content */}
                            {expandedSections[path] && (
                                <div className="accordion-content">
                                    {section.content && <p>{section.content}</p>}
                                    {section.subsections && renderAccordion(section.subsections, path)} {/* Recursively render subsections */}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        );
    };


    return (
        <div className="syllabus-viewer">
            <h1>Syllabus Viewer</h1>

            {/* Category Buttons */}
            <div className="category-buttons">
                {categories.map((category) => (
                    <button
                        key={category.label}
                        className={selectedCategory === category.label ? "active" : ""}
                        onClick={() => filterHeadings(category.label)}
                    >
                        {category.label}
                    </button>
                ))}
            </div>

            {/* Table of Contents */}
            <div className="toc-container">
                <h2>Table of Contents</h2>
                {error && <p className="error-message">{error}</p>}
                <div className="toc-grid">
                    {filteredHeadings.map((item, index) => (
                        <div
                            key={index}
                            className="toc-item"
                            onClick={() => fetchData(item)}
                        >
                            {item} {/* Display the heading string */}
                        </div>
                    ))}
                </div>
            </div>

            {/* Hierarchical Data Display */}
            <div className="data-container">
                {loading && <p className="loading-message">Loading...</p>}
                {data && (
                    <div>
                        <h2>{selectedHeading}</h2>
                        {renderAccordion(data)} {/* Render using accordion layout */}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SyllabusViewer;
