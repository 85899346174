import React, {useEffect, useRef, useState} from 'react';
import axios from 'axios';
import '../../css/SearchPage.css';
import {
    Document,
    Paragraph,
    Packer,
    HeadingLevel,
    TextRun,
    TableCell,
    Table,
    WidthType,
    VerticalAlign,
    TableRow
} from 'docx';
import {getToken} from "../../auth";


const subjects = ['Mathematics', 'English', 'Science', 'HSIE', 'PDHPE', 'Technology and Applied Studies', 'Creative Arts'];
const stages = ['Early Stage 1', 'Stage 1', 'Stage 2', 'Stage 3', 'Stage 4', 'Stage 5', 'Stage 6', 'Life Skills'];

const SearchPage = () => {
    const [query, setQuery] = useState('');
    const [results, setResults] = useState([]);
    const [formattedPrompt, setFormattedPrompt] = useState('');
    const [aiResponse, setAIResponse] = useState(''); // New state for AI response
    const [loading, setLoading] = useState(false);
    const [selectedSubject, setSelectedSubject] = useState(null);
    const [selectedStage, setSelectedStage] = useState(null);
    const [isPromptVisible, setIsPromptVisible] = useState(true);
    const [recentQueries, setRecentQueries] = useState(() => {
        const saved = localStorage.getItem('recentQueries');
        return saved ? JSON.parse(saved) : [];
    });
    const [aiResponses, setAiResponses] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [savedSearches, setSavedSearches] = useState([]);
    const [selectedModel, setSelectedModel] = useState('claude');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [toast, setToast] = useState({ visible: false, message: '' });
    const [isRawView, setIsRawView] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const textareaRef = useRef(null);



    useEffect(() => {
        if (textareaRef.current) {
            // Store current scroll position
            const scrollPos = window.scrollY;

            const textarea = textareaRef.current;
            // Get current cursor position
            const cursorPosition = textarea.selectionStart;

            textarea.style.height = 'auto';
            textarea.style.height = `${textarea.scrollHeight}px`;

            // Restore cursor position
            textarea.setSelectionRange(cursorPosition, cursorPosition);
            // Restore scroll position
            window.scrollTo(0, scrollPos);
        }
    }, [query]);


    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') {
                setIsModalOpen(false);
            }
        };

        if (isModalOpen) {
            window.addEventListener('keydown', handleEscape);
        }

        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, [isModalOpen]);



    const loadingMessages = [
        "Sending to the Syllabus Library",
        "Hmm... pondering your Question",
        "Stitching together a great query",
        "Asking the machine to do some thinking",
        "gosh, that takes an age....",
        "How has your day been going",
        "About that weather, hey!",
        "Have you got plans this weekend",
        "... Ok, Great, we're Done!",
        "Potentially ??",
        "How did I get this so wrong?",
        "I really have to evaluate things better",
        "Next time I will be more thorough, promise",
        "... I am going to have to talk to the engineers",
        "this is crazy, I am so sorry",
        "I'm not sure what's actually going on back there"
    ];

    useEffect(() => {
        let messageIndex = 0;
        let interval;

        if (loading) {
            setLoadingMessage(loadingMessages[0]);
            interval = setInterval(() => {
                messageIndex = (messageIndex + 1) % loadingMessages.length;
                setLoadingMessage(loadingMessages[messageIndex]);
            }, 2400); // Change message every 3 seconds
        }

        return () => clearInterval(interval);
    }, [loading]);

    const showToast = (message) => {
        setToast({ visible: true, message });
        setButtonDisabled(true);
        setTimeout(() => {
            setToast({ visible: false, message: '' });
            setButtonDisabled(false);
        }, 4000);
    };
    const parseMarkdown = (content) => {
        return content.split('\n').reduce((acc, line) => {
            const trimmedLine = line.trim();
            if (!trimmedLine) return acc;

            // Skip table separator rows that only contain dashes and pipes
            if (/^[\-\|]+$/.test(trimmedLine.replace(/\s/g, ''))) {
                return acc;
            }

            const section = {
                type: 'paragraph',
                content: trimmedLine,
                level: 0
            };
            // Handle all header levels
            if (trimmedLine.startsWith('#')) {
                section.type = 'header';
                // Remove all # symbols and trim whitespace
                section.content = trimmedLine.replace(/^#+\s*/, '').trim();
                // Count # symbols for heading level
                section.level = (trimmedLine.match(/^#+/) || [''])[0].length;
            }
            // Handle table rows
            if (trimmedLine.includes('|')) {
                section.type = 'table-row';
                section.content = trimmedLine.split('|')
                    .filter(cell => cell.trim())
                    .map(cell => cell.trim());
            }
            else if (trimmedLine.startsWith('-')) {
                section.type = 'bullet';
                section.content = trimmedLine.replace('-', '').trim();
            }
            else if (trimmedLine.includes('|')) {
                section.type = 'table-row';
                section.content = trimmedLine.split('|')
                    .filter(cell => cell.trim())
                    .map(cell => cell.trim());
            }

            acc.push(section);
            return acc;
        }, []);
    };


    const formatBoldText = (text) => {
        // Handle non-string inputs
        if (typeof text !== 'string') {
            return [{ type: 'text', content: String(text), key: 0 }];
        }

        return text.split(/(\*\*.*?\*\*)/).map((part, index) => {
            if (part.startsWith('**') && part.endsWith('**')) {
                const content = part.replace(/\*\*/g, '');
                return { type: 'bold', content, key: index };
            }
            return { type: 'text', content: part, key: index };
        });
    };


    const FormattedContent = ({ content }) => {
        const isHtml = content.includes('<') && content.includes('>');

        if (isHtml) {
            return (
                <div
                    className="formatted-content"
                    dangerouslySetInnerHTML={{ __html: content }}
                />
            );
        }
        const sections = parseMarkdown(content);
        let isInTable = false;

        return (
            <div className="formatted-content">
                {sections.map((section, index) => {
                    const formattedParts = formatBoldText(section.content);

                    switch (section.type) {
                        case 'table-row':
                            if (!isInTable) {
                                isInTable = true;
                                return (
                                    <table key={index} className="content-table">
                                        <tr className="table-row">
                                            {section.content.map((cell, cellIndex) => (
                                                <th key={cellIndex} className="table-header">{cell}</th>
                                            ))}
                                        </tr>
                                    </table>
                                );
                            }
                            return (
                                <tr key={index} className="table-row">
                                    {section.content.map((cell, cellIndex) => (
                                        <td key={cellIndex} className="table-cell">{cell}</td>
                                    ))}
                                </tr>
                            );
                        case 'header':
                            return (
                                <h3 key={index} className="content-heading">
                                    {formattedParts.map(part =>
                                        part.type === 'bold'
                                            ? <strong key={part.key}>{part.content}</strong>
                                            : part.content
                                    )}
                                </h3>
                            );

                        case 'bullet':
                            return (
                                <div key={index} className="content-bullet">
                                    <span className="bullet-marker">•</span>
                                    <span className="bullet-content">
                  {formattedParts.map(part =>
                      part.type === 'bold'
                          ? <strong key={part.key}>{part.content}</strong>
                          : part.content
                  )}
                </span>
                                </div>
                            );

                        default:
                            return (
                                <p key={index} className="content-paragraph">
                                    {formattedParts.map(part =>
                                        part.type === 'bold'
                                            ? <strong key={part.key}>{part.content}</strong>
                                            : part.content
                                    )}
                                </p>
                            );
                    }
                })}
            </div>
        );
    };


    const createDocxContent = (content, Document, Paragraph, TextRun, HeadingLevel) => {
        const sections = parseMarkdown(content);
        let documentElements = [];
        let currentTableRows = [];

        sections.forEach(section => {
            if (section.type === 'table-row') {
                const tableCells = section.content.map(cellContent =>
                    new TableCell({
                        children: [
                            new Paragraph({
                                children: [
                                    new TextRun({
                                        text: cellContent,
                                        font: "Calibri",
                                        size: 24
                                    })
                                ]
                            })
                        ],
                        verticalAlign: VerticalAlign.CENTER
                    })
                );

                currentTableRows.push(new TableRow({ children: tableCells }));
            } else {
                if (currentTableRows.length > 0) {
                    documentElements.push(new Table({ rows: currentTableRows }));
                    currentTableRows = [];
                }

                // Handle other content types as before
                const formattedParts = formatBoldText(section.content);
                documentElements.push(new Paragraph({
                    children: formattedParts.map(part => new TextRun({
                        text: part.content,
                        bold: part.type === 'bold',
                        font: "Calibri",
                        size: 24
                    })),
                    ...(section.type === 'header' && { heading: HeadingLevel.HEADING_1 }),
                    ...(section.type === 'bullet' && { bullet: { level: 0 } })
                }));
            }
        });

        if (currentTableRows.length > 0) {
            documentElements.push(new Table({ rows: currentTableRows }));
        }

        return documentElements;
    };


    const loadSavedSearches = async () => {
        try {
            const token = getToken();
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/syllabus/saved-searches`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                withCredentials: true
            });
            // Transform the data to match our UI expectations
            const formattedResponses = response.data.map(search => {
                let title = search.aiResponse;
                let aiContent = search.aiResponse;

                if (title) {
                    const lines = title.split('\n');
                    if (lines[0].startsWith('```markdown') || lines[0].startsWith('```')) {
                        title = lines[1].replace(/^#+\s*/, '').trim();
                        aiContent = lines.slice(1).join('\n'); // Skip the ```markdown line
                    } else {
                        title = lines[0].replace(/^#+\s*/, '').trim();
                    }
                }

                return {
                    id: search._id,
                    title: title || 'Untitled Response',
                    content: aiContent, // Use the modified content
                    query: search.query,
                    subject: search.subject,
                    stage: search.stage,
                    timestamp: search.createdAt
                };
            });
            setAiResponses(formattedResponses);
        } catch (error) {
            console.error('Error loading saved searches:', error);
        }
    };


    useEffect(() => {
        loadSavedSearches();
    }, []);

    const handleDelete = async (responseId, title) => {
        if (window.confirm(`Are you sure you want to delete "${title}"?`)) {
            try {
                const token = getToken();
                await axios.delete(`${process.env.REACT_APP_BASE_URL}/syllabus/saved-searches/${responseId}`, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                await loadSavedSearches(); // Reload after deletion
            } catch (error) {
                console.error('Error deleting search:', error);
            }
        }
    };

    const handleSearch = async () => {
        if (!selectedSubject || !selectedStage) {
            showToast('Please select both a Subject and Stage before searching');
            return;
        }

        // Trigger scroll-to-top only when a search is performed


        const newResponseId = Date.now();
        const newResponse = {
            id: newResponseId,
            title: 'Working...',
            content: '',
            query,
            subject: selectedSubject,
            stage: selectedStage,
            modelChoice: selectedModel,
            timestamp: new Date().toISOString()
        };

        setAiResponses(prev => [newResponse, ...prev]);
        showToast('Search submitted - processing will take a few moments');

        setLoading(true);
        try {
            const token = getToken();
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/syllabus/vector-search`,
                {
                    query,
                    subject: selectedSubject,
                    stage: selectedStage,
                    modelChoice: selectedModel // Include model choice in request
                },
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );

            await loadSavedSearches();

        } catch (error) {
            console.error('Search error:', error);
        }
        setLoading(false);
    };

    const downloadAsDocx = async (content, title) => {
        const processTextRuns = (text) => {
            const parts = text.split(/(\*\*.*?\*\*)/g);
            return parts.map(part => {
                if (part.startsWith('**') && part.endsWith('**')) {
                    return new TextRun({
                        text: part.replace(/\*\*/g, ''),
                        bold: true,
                        font: "Calibri"
                    });
                }
                return new TextRun({
                    text: part,
                    font: "Calibri"
                });
            });
        };

        const doc = new Document({
            styles: {
                default: {
                    document: {
                        run: {
                            font: "Calibri",
                            size: 24
                        }
                    }
                },
                paragraphStyles: [{
                    id: "Heading1",
                    name: "Heading 1",
                    basedOn: "Normal",
                    next: "Normal",
                    run: {
                        font: "Calibri",
                        size: 32,
                        bold: true
                    }
                }]
            },
            sections: [{
                properties: {},
                children: createDocxContent(content, Document, Paragraph, TextRun, HeadingLevel)
            }]
        });

        Packer.toBlob(doc).then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${title}.docx`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        });
    };

    const suggestions = [
        {
            title: "Creative Writing Unit",
            prompt: "Create a creative writing unit that focuses on character development, story structure, and descriptive language. Include engaging writing prompts and peer review activities"
        },
        {
            title: "Science Experiments",
            prompt: "Design a series of hands-on science experiments suitable for the classroom that demonstrate key concepts in physics and chemistry. Include safety guidelines and materials needed"
        },
        {
            title: "Digital Skills Project",
            prompt: "Create a term-long project where students build a digital portfolio. Include weekly milestones covering research skills, digital citizenship, content creation and online collaboration"
        },
        {
            title: "Sports Leadership",
            prompt: "Design a comprehensive unit that combines physical skills development with leadership training. Include team building activities, communication exercises, and opportunities for students to lead warm-ups and mini-sessions"
        },
        {
            title: "Environmental Study",
            prompt: "Develop an integrated unit on environmental sustainability that combines science, mathematics and community action. Include data collection, analysis and a final project that impacts the local community"
        },
        {
            title: "Cultural Festival",
            prompt: "Create a multi-week plan culminating in a cultural celebration. Include research components, artistic elements, performance preparation, and food studies while meeting curriculum requirements"
        },
        {
            title: "Mixed Ability Basketball",
            prompt: "I have a mixed ability class of 24 students, including one student in a wheelchair and two with mild coordination challenges. Need a 5-week basketball unit.\n" +
                "Available Equipment:\n" +
                "\n" +
                "15 basketballs (8 need pumping)\n" +
                "2 adjustable height hoops\n" +
                "4 portable hoops\n" +
                "Set of colored bibs\n" +
                "\n" +
                "Available Space:\n" +
                "\n" +
                "Full indoor court\n" +
                "Small outdoor court (weather permitting)\n" +
                "\n" +
                "Looking for activities that ensure everyone can participate meaningfully, especially during team games."
        },
        {
            title: "Intro Email Writing",
            prompt: "help write a short letter to parents, welcoming them to the start of the school year. With a simple and friendly introduction from me, and an overview of what we will learn, and how I will make it fun and meaningful so it's easier to get the maximum from the lessons"
        }

    ];


    const formatAIResponse = (response) => {
        const lines = response.split('\n').filter(line => line.trim() !== '');
        return lines.map((line, index) => {
            if (line.startsWith('###')) {
                return <h3 key={index} className="ai-response-heading">{line.replace('###', '').trim()}</h3>;
            } else if (line.startsWith('-')) {
                const content = line.replace('-', '').trim();
                return <li key={index} className="ai-response-subpoint">
                    {content.split(/(\*\*.*?\*\*)/g).map((part, i) => {
                        if (part.startsWith('**') && part.endsWith('**')) {
                            return <strong key={i}>{part.replace(/\*\*/g, '')}</strong>;
                        }
                        return part;
                    })}
                </li>;
            } else {
                return <p key={index} className="ai-response-paragraph">
                    {line.trim().split(/(\*\*.*?\*\*)/g).map((part, i) => {
                        if (part.startsWith('**') && part.endsWith('**')) {
                            return <strong key={i}>{part.replace(/\*\*/g, '')}</strong>;
                        }
                        return part;
                    })}
                </p>;
            }
        });
    };



    const copyToClipboard = (text) => {
        const textArea = document.createElement('textarea');
        textArea.value = text;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        alert('Content copied to clipboard');
    };

    return (
        <div className="search-container">
            {toast.visible && (
                <div className="toast">
                    {toast.message}
                </div>
            )}
            <div className="files-section">
                <h3>Previous Creations</h3>
                <div className="response-files">
                    {aiResponses.map(response => (
                        <div
                            key={response.id}
                            className="response-file"
                            data-status={response.title === 'Working...' ? 'working' : 'complete'}
                        >
                            {response.title !== 'Working...' && (
                                <button
                                    onClick={() => handleDelete(response.id, response.title)}
                                    className="delete-icon"
                                    title="Delete response"
                                >
                                    ×
                                </button>
                            )}
                            <button className="file-button" title={response.title}>
                                {response.title === 'Working...' ? (
                                    <>
                                        <div className="spinner"></div>
                                        <div className="loading-message">{loadingMessage}</div>
                                    </>
                                ) : (
                                    <span className="file-name">{response.title}</span>
                                )}
                            </button>
                            {response.title !== 'Working...' && (
                                <div className="file-actions">
                                    <button
                                        onClick={() => {
                                            setAIResponse(response.content);
                                            setIsModalOpen(true);
                                        }}
                                        className="action-button"
                                    >
                                        View
                                    </button>
                                    <button
                                        onClick={() => downloadAsDocx(response.content, response.title)}
                                        className="action-button"
                                    >
                                        Download
                                    </button>
                                </div>
                            )}
                        </div>
                    ))}
                </div>
                <div className="scroll-indicator" style={{fontSize: '36px', fontWeight: 'bold'}}>→</div>
            </div>
            <div className="search-surround">
                <div className="filter-section">
                    <div className="subject-buttons">
                        {subjects.map(subject => (
                            <button
                                key={subject}
                                onClick={() => setSelectedSubject(subject)}
                                className={`filter-button ${selectedSubject === subject ? 'selected' : ''}`}
                            >
                                {subject}
                            </button>
                        ))}
                    </div>
                    <div className="stage-buttons">
                        {stages.map(stage => (
                            <button
                                key={stage}
                                onClick={() => setSelectedStage(stage)}
                                className={`filter-button ${selectedStage === stage ? 'selected' : ''}`}
                            >
                                {stage}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="model-toggle">
                    <button
                        className={`model-button ${selectedModel === 'claude' ? 'selected' : ''}`}
                        onClick={() => setSelectedModel('claude')}
                    >
                        <img
                            src="/images/claudeai-chat-logo-icon.png"
                            alt="Claude"
                            className="model-icon"
                        />
                        Claude
                    </button>
                    <button
                        className={`model-button ${selectedModel === 'openai' ? 'selected' : ''}`}
                        onClick={() => setSelectedModel('openai')}
                    >
                        <img
                            src="/images/openai-chatgpt-logo-icon.png"
                            alt="OpenAI"
                            className="model-icon"
                        />
                        ChatGPT
                    </button>
                </div>

                <div className="search-box">
                    <textarea
                        ref={textareaRef}
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        className="search-input"
                        placeholder="Type your query here..."
                        onFocus={(e) => e.preventDefault()} // Prevent browser from scrolling on focus
                    />
                    <button
                        onClick={handleSearch}
                        disabled={!query.trim() || buttonDisabled}
                        className="search-button"
                        title={!query.trim() ? "Please enter a search query" : "Search"}
                    >
                        <span className="search-icon">↑</span>
                    </button>
                </div>

                {suggestions.length > 0 && (
                    <div>
                        <div className="recent-title">Suggested Queries</div>
                        <div className="recent-queries">
                            <div className="recent-container">
                                {suggestions.map((suggestion, index) => (
                                    <button
                                        key={index}
                                        onClick={() => setQuery(suggestion.prompt)}
                                        className="recent-query-button"
                                    >
                                        {suggestion.title}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>



            {/*{formattedPrompt && (*/}
            {/*    <div className="prompt-container">*/}
            {/*        <div className="prompt-header">*/}
            {/*            <h2>Generated Prompt</h2>*/}
            {/*            <div className="prompt-controls">*/}
            {/*                <button onClick={copyPromptToClipboard} className="control-button">*/}
            {/*                    Copy*/}
            {/*                </button>*/}
            {/*                <button*/}
            {/*                    onClick={() => setIsPromptVisible(!isPromptVisible)}*/}
            {/*                    className="control-button"*/}
            {/*                >*/}
            {/*                    {isPromptVisible ? 'Hide' : 'Show'}*/}
            {/*                </button>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*        {isPromptVisible && (*/}
            {/*            <pre className="prompt-content">{formattedPrompt}</pre>*/}
            {/*        )}*/}
            {/*    </div>*/}
            {/*)}*/}




            <div className="results-container">
                {results
                    .filter(result => result.content && result.wordCount < 1000)
                    .map((result, index) => (
                        <div key={index} className="result-card">
                            <h3 className="result-heading">{result.heading}</h3>
                            <div className="metrics-container">
                                <div className="metric">
                                    Word Count: {result.wordCount}
                                </div>
                                <div className="metric">
                                    Similarity: {result.similarityScore.toFixed(4)}
                                </div>
                            </div>
                            <p className="result-content">{result.content}</p>
                            {result.metadata && (
                                <div className="metadata">
                                    <strong>Metadata:</strong>
                                    <pre>{JSON.stringify(result.metadata, null, 2)}</pre>
                                </div>
                            )}
                        </div>
                    ))}
            </div>
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2>View Results</h2>
                            <div className="modal-actions">
                                <button
                                    onClick={() => setIsRawView(!isRawView)}
                                    className="action-button"
                                >
                                    {isRawView ? 'Show Formatted' : 'Show Raw'}
                                </button>
                                <button
                                    onClick={() => copyToClipboard(aiResponse)}
                                    className="action-button"
                                >
                                    Copy
                                </button>
                                <button
                                    className="modal-close"
                                    onClick={() => setIsModalOpen(false)}
                                >
                                    ×
                                </button>
                            </div>
                        </div>
                        <div className="modal-body">
                            {isRawView ? (
                                <pre className="raw-content">{aiResponse}</pre>
                            ) : (
                                <FormattedContent content={aiResponse} />
                            )}
                        </div>
                    </div>
                </div>
            )}



        </div>
    );
};

export default SearchPage;
